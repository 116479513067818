import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'

type TableProps = {
  children: React.ReactNode
  className?: string
  border?: boolean
  testId?: string
} & Omit<React.ComponentProps<'table'>, 'border'>

export function Table({ children, className, border, testId, ...restProps }: TableProps) {
  return (
    <table
      {...getTestId(testId)}
      className={clsx(
        'bg-white',
        { 'border-solid border border-gray-200': border !== false },
        className,
      )}
      {...restProps}
    >
      {children}
    </table>
  )
}
