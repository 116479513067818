import { faArrowDown91, faArrowDownAZ, faClock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Menu } from '@northvolt/form'
import {
  BatterySystemKind,
  OrderBy,
  SortBy,
  SystemsByIdsQuery,
  SystemsByIdsQueryVariables,
  SystemsListQuery,
  SystemsListQueryVariables,
  useGQL,
} from '@northvolt/gql'
import { useMemo, useState } from 'react'
import { Fadein } from '../Fadein'
import { systemsByIdsQuery, systemsListQuery } from '../systemQueries'
import { SystemType } from '../types'
import { SystemListBody } from './SystemListBody'

export type SystemListProps = {
  search: string
  systemType: SystemType
}

const LIMIT = 50

export function SystemList({ search, systemType }: SystemListProps) {
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Observed)

  const filter = useMemo<SystemsListQueryVariables>(() => {
    const batteryTypeKindMap: Record<SystemType, BatterySystemKind[]> = {
      all: [BatterySystemKind.Pack, BatterySystemKind.Subpack, BatterySystemKind.Hub],
      voltpacks: [BatterySystemKind.Pack],
      cores: [BatterySystemKind.Subpack],
      volthubs: [BatterySystemKind.Hub],
    }
    return {
      filter: {
        // right now the API will sort null observed values ahead of other values
        // so if we are sorting by observed and the user is not actively searching
        // for a specific system we include state === null values
        // TODO fix this on the API side
        hasState: sortBy === SortBy.Observed && search.trim() !== '' ? undefined : true,
        search,
        kind: batteryTypeKindMap[systemType],
        limit: LIMIT,
        orderBy: sortBy === SortBy.Observed ? OrderBy.Descending : OrderBy.Ascending,
        sortBy,
      },
    }
  }, [search, systemType, sortBy])

  /*
   * We make the filter query one time and then poll by the ids returned from it
   * this keeps the list ordering consistent when ordering by "Last seen"
   * otherwise the items would jump around on the list on every polling
   * if the user reloads the page or changes the search it will trigger the filter
   * query again
   */
  // TODO change polling query to not use React.Suspense to prevent double-query
  // ie use the data from the previous query before triggering this one
  const { data: initialData } = useGQL<SystemsListQuery, SystemsListQueryVariables>(
    systemsListQuery,
    filter,
  )

  const systemIds = useMemo<SystemsByIdsQueryVariables>(() => {
    return {
      ids: initialData.systems.items.map((system) => {
        return system.id
      }),
    }
  }, [initialData])

  // polling
  const { data } = useGQL<SystemsByIdsQuery, SystemsByIdsQueryVariables>(
    systemsByIdsQuery,
    systemIds,
    {
      refreshInterval: 5000,
    },
  )
  const systems = data?.systemsByIDs?.items ?? []
  const systemsCount = systems.length

  let itemName: string
  switch (systemType) {
    case 'voltpacks':
      itemName = 'Voltpacks'
      break
    case 'cores':
      itemName = 'Cores'
      break
    default:
      itemName = 'Systems'
      break
  }

  return (
    <Fadein key={systems.map(({ id }) => id).join('-')}>
      <div className="flex flex-col mx-auto w-11/12 xl:w-9/12">
        <div className="flex justify-between mb-12">
          <div>
            <h1 className="text-3xl font-semibold">
              {search.length === 0 && systemsCount === 0 && `No ${itemName} found`}
              {search.length === 0 && systemsCount > 0 && `${itemName} search results`}
              {search.length > 0 && systemsCount === 0 && `No ${itemName} found for: ${search}`}
              {search.length > 0 && systemsCount > 0 && `${itemName} search results for: ${search}`}
            </h1>
          </div>
          <div>
            <SortByMenu selectedSorting={sortBy} setSelectedSorting={setSortBy} />
          </div>
        </div>

        <div className="mb-8">
          <SystemListBody systemList={systems} />
        </div>

        {systemsCount === 0 ? (
          <div className=" text-center my-5">There are no items found for this search</div>
        ) : null}
      </div>
    </Fadein>
  )
}

const sortByTitles: { [key in Exclude<SortBy, SortBy.Kind>]: string } = {
  [SortBy.Observed]: 'Last Seen',
  [SortBy.DisplayName]: 'Name',
  [SortBy.Id]: 'ID',
}

function SortByMenu({
  selectedSorting,
  setSelectedSorting,
}: {
  selectedSorting: SortBy
  setSelectedSorting: (selectedSorting: SortBy) => void
}) {
  const menuButton = (
    <Button
      disableActiveStyle
      size="medium"
      style="text"
    >{`Sort by ⌁ ${sortByTitles[selectedSorting as Exclude<SortBy, SortBy.Kind>]}`}</Button>
  )

  return (
    <div className="mr-2">
      <Menu
        alignMenu="right"
        button={menuButton}
        list={[
          {
            children: (
              <>
                <FontAwesomeIcon className="mr-4 text-gray-700" icon={faClock} />
                {sortByTitles[SortBy.Observed]}
              </>
            ),
            onClick: () => setSelectedSorting(SortBy.Observed),
          },
          {
            children: (
              <>
                <FontAwesomeIcon className="mr-3 text-gray-700" icon={faArrowDown91} />
                {sortByTitles[SortBy.Id]}
              </>
            ),
            onClick: () => setSelectedSorting(SortBy.Id),
          },
          {
            children: (
              <>
                <FontAwesomeIcon className="mr-3 text-gray-700" icon={faArrowDownAZ} />
                {sortByTitles[SortBy.DisplayName]}
              </>
            ),
            onClick: () => setSelectedSorting(SortBy.DisplayName),
          },
        ]}
      />
    </div>
  )
}
