import clsx from 'clsx'
import { useEffect, useState } from 'react'

export const Loader = () => {
  const circleCommonClasses = 'h-2.5 w-2.5 bg-current rounded-full'

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <div className={clsx('w-96 mx-auto text-center my-16', { hidden: !visible })}>
      <div className="mr-4 text-xl font-semibold">Loading...</div>
      <div className="flex pt-4 align-middle">
        <div className="mx-auto flex">
          <div className={`${circleCommonClasses} mr-1 animate-bounce`} />
          <div className={`${circleCommonClasses} mr-1 animate-bounce200`} />
          <div className={`${circleCommonClasses} animate-bounce400`} />
        </div>
      </div>
    </div>
  )
}
