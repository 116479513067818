import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { toastError, toastSuccess } from '@northvolt/error-handling'
import {
  Button,
  Dropdown,
  Input,
  InputMultiselect,
  UseFormErrors,
  Validate,
  useForm,
} from '@northvolt/form'
import {
  BatterySystemGeneration,
  CreateCoreMutation,
  CreateCoreMutationVariables,
  CreateSecureElementMutation,
  CreateSecureElementMutationVariables,
  gql,
  useGQLMutation,
} from '@northvolt/gql'
import { useEffect, useState } from 'react'
import { getSystemURL } from '../../utils/getSystemURL'
import { SecureElementInput, createSecureElementMutation } from './CreateSecureElementFingerprint'
import { useEntityRelationships } from './useEntityRelationships'

const createCoreMutation = gql`
  mutation createCore($id: ID!, $core: CreateCoreInput!, $tags: [String!]!) {
    createCore(id: $id, coreDetails: $core) {
      success
    }

    setCoreTags(id: $id, tags: $tags) {
      success
    }
  }
`

type CreateCoreData = {
  id: string
  displayName: string
  mac: string
  tenant: null | {
    label: string
    value: string
  }
  generation: null | {
    label: string
    value: BatterySystemGeneration
  }
  tags: string[]
  /**
   * fingerprint is optional at this point, user can always add one later through the
   * CreateSecureElementFingerprint modal
   */
  fingerprint: string

  hardwareSe: boolean
}

export function CreateCore({ onSuccess }: { onSuccess: () => void }) {
  const [coreId, setCoreId] = useState<string>('')
  const { mutation, result } = useGQLMutation<CreateCoreMutation, CreateCoreMutationVariables>(
    createCoreMutation,
  )
  const { mutation: fingerprintMutation, result: fingerprintResult } = useGQLMutation<
    CreateSecureElementMutation,
    CreateSecureElementMutationVariables
  >(createSecureElementMutation)
  const { tenants, generations, tags } = useEntityRelationships('core')

  useEffect(() => {
    if (result?.state === 'success') {
      toastSuccess(
        'Core created successfully',
        <div>
          <a
            className="pt-2 h-auto transition ease-in duration-200 border-b-2 hover:text-powerGreen hover:border-transparent border-powerGreen"
            href={getSystemURL(coreId)}
            target="__blank"
          >
            View in detail page
          </a>
        </div>,
      )
      onSuccess()
    } else if (result?.state === 'error') {
      toastError('Error creating Core', result.errors)
    }
  }, [result, onSuccess, coreId])

  const { getFieldPropsByName, onSubmit, ...state } = useForm<CreateCoreData>({
    initialValues: {
      id: '',
      displayName: '',
      tenant: null,
      generation:
        generations.find((v) => {
          return v.value === BatterySystemGeneration.D
        }) ?? null,
      mac: '',
      tags: [],
      fingerprint: '',
      hardwareSe: true,
    },
    onSubmit: async (formData) => {
      if (document.activeElement && document.activeElement.tagName === 'BUTTON') {
        setCoreId(formData.id)
        const fingerprint = formData.fingerprint.trim()
        if (fingerprint.length !== 0) {
          await fingerprintMutation({
            fingerprint,
            hardwareSe: formData.hardwareSe,
            tenant: formData.tenant?.value as string,
          })
        }
        await mutation({
          id: (formData.id as string).trim(),
          core: {
            displayName: formData.displayName,
            tenant: formData.tenant?.value as string,
            generation: formData.generation?.value as BatterySystemGeneration,
            macAddress: Validate.getMacAddressFromInput(formData.mac),
          },
          tags: formData.tags,
        })
      }
    },
    onValidate: (values) => {
      const errors: UseFormErrors<CreateCoreData> = {}
      if (values.id.length === 0) {
        errors.id = 'Please enter an ID'
      }
      if (values.id.length > 16) {
        errors.id = 'ID cannot be longer than 16 characters'
      }
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name'
      }
      if (values.generation == null) {
        errors.generation = 'Please select a generation'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a tenant'
      }
      if (!Validate.testMacAddress(values.mac)) {
        errors.mac = Validate.macAddressAllowedFormats
      }
      if (values.fingerprint.length === 0) {
        errors.fingerprint = 'Please enter a fingerprint value.'
      } else if (!/^MD5(:[0-9a-fA-F]{2}){16}$/.test(values.fingerprint)) {
        errors.fingerprint = 'Fingerprint should have the format MD5:01:23:45:67:89:ab:cd:…:ef.'
      }
      return errors
    },
  })

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <Input className="mb-2" label="ID" required tabIndex={1} {...getFieldPropsByName('id')} />
        <Input
          className="mb-2"
          label="Display name"
          required
          tabIndex={2}
          {...getFieldPropsByName('displayName')}
        />
        <div className="mb-2">
          <Dropdown label="Tenant" list={tenants} tabIndex={3} {...getFieldPropsByName('tenant')} />
        </div>
        <div className="mb-2">
          <Dropdown
            label="Generation"
            list={generations}
            tabIndex={4}
            {...getFieldPropsByName('generation')}
          />
        </div>
        <Input
          className="mb-2"
          label="Mac address"
          required
          tabIndex={5}
          {...getFieldPropsByName('mac')}
        />

        <SecureElementInput
          fingerprint={getFieldPropsByName('fingerprint')}
          hardwareSe={getFieldPropsByName('hardwareSe')}
          required={false}
        />

        <InputMultiselect
          autocomplete={tags}
          className="mb-2"
          label="Tags"
          tabIndex={6}
          {...getFieldPropsByName('tags')}
        />
        <div className="mb-2 flex md:justify-end">
          <Button
            className="w-full md:w-auto"
            disabled={
              state.formHasErrors ||
              fingerprintResult?.state === 'loading' ||
              result?.state === 'loading' ||
              state.isSubmitting
            }
            prefix={faSave}
            style="primary"
            tabIndex={7}
            type="submit"
          >
            Create Core
          </Button>
        </div>
      </form>
    </div>
  )
}
