import { useLayoutEffect, useRef, useState } from 'react'

const STORAGE_KEY_PREFIX = 'use-store-prefix-'

/**
 * like React's setState
 */
type SetValue<T> = (newValue: T | ((currentValue: T) => T)) => void

function getValue<T>(
  storageKey: string,
  defaultValue: T,
  storage: Storage,
  reviver?: ReviverFunc,
): T {
  try {
    const item = storage.getItem(storageKey)
    if (item == null) {
      return defaultValue
    }

    return JSON.parse(item, reviver)
  } catch (e) {
    return defaultValue
  }
}

type ReviverFunc = (key: string, value: any) => any

type StorageStateOptions = {
  sessionStorage?: boolean
  reviver?: ReviverFunc
}

export function useStorageState<T>(
  key: string,
  defaultValue: T,
  options?: StorageStateOptions,
): [T, SetValue<T>] {
  const storage = options?.sessionStorage ? window.sessionStorage : window.localStorage
  const storageKey = `${STORAGE_KEY_PREFIX}${key}`
  const previousStorageKey = useRef(storageKey)
  const previousStorage = useRef(storage)
  const [currentValue, setCurrentValue] = useState<T>(() =>
    getValue(storageKey, defaultValue, storage, options?.reviver),
  )

  useLayoutEffect(() => {
    if (previousStorageKey.current !== storageKey || previousStorage.current !== storage) {
      // storageKey or storage changed, meaning we need to get the value from storage again
      previousStorageKey.current = storageKey
      previousStorage.current = storage
      setCurrentValue(getValue(storageKey, defaultValue, storage, options?.reviver))
    } else {
      /**
       * Sync the value to the appropriate storage.
       */
      storage.setItem(storageKey, JSON.stringify(currentValue))
    }
    // defaultValue could be complex object
    // eslint-disable-next-line
  }, [currentValue, storageKey, storage])

  return [currentValue, setCurrentValue]
}
