import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'
import React, { ForwardedRef } from 'react'

type TableRowProps = {
  children: React.ReactNode
  className?: string
  testId?: string
} & React.ComponentProps<'tr'>

function TableRowInner(
  { children, className, testId, ...restProps }: TableRowProps,
  forwardedRef?: ForwardedRef<HTMLTableRowElement>,
) {
  if (forwardedRef != null) {
    restProps.ref = forwardedRef
  }
  return (
    <tr
      className={clsx('border-solid border-b border-gray-200', className)}
      {...getTestId(testId)}
      {...restProps}
    >
      {children}
    </tr>
  )
}

export const TableRow = React.forwardRef(TableRowInner)
