import { getTestId } from '@northvolt/test-utils'

type TableBodyProps = {
  children: React.ReactNode
  testId?: string
} & React.ComponentProps<'tbody'>

export function TableBody({ children, testId, ...restProps }: TableBodyProps) {
  return (
    <tbody {...getTestId(testId)} {...restProps}>
      {children}
    </tbody>
  )
}
