import { faQrcode } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@northvolt/form'
import { BaseModal } from '@northvolt/modal'
import React, { Suspense, useState } from 'react'
import { Loader } from '../../Loader'

const LazyScanner = React.lazy(async () => {
  const { Scanner } = await import('./Scanner')
  return { default: Scanner }
})

export function QRButton({ tabIndex }: { tabIndex?: number }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button
        className="whitespace-nowrap w-full"
        onClick={() => setIsOpen(true)}
        prefix={<FontAwesomeIcon icon={faQrcode} />}
        size="large"
        style="primary"
        tabIndex={tabIndex}
      >
        Scan QR
      </Button>

      <BaseModal
        className="max-w-[600px] min-w-full md:min-w-[640px] min-h-[200px]  md:min-h-[520px]"
        header="Scan QR code"
        onClose={() => {
          setIsOpen(false)
        }}
        visible={isOpen}
      >
        <Suspense fallback={<Loader />}>
          <LazyScanner />
        </Suspense>
      </BaseModal>
    </>
  )
}
