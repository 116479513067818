import { BatterySystemKind } from '@northvolt/gql'
import { Tenant } from '@northvolt/tenant-metadata'

// TODO: Currently no good way to figure out if a core has an entry in SPT.
// We'd like to check system.parentSystem.id and display links for standalone
// cores, e.g. no parent system present, but that data is not resolved for list queries.
// So presently we only display links for pack/system entities.
export function hasSPTIntegration(kind: BatterySystemKind | null | undefined, tenant: Tenant) {
  return kind === BatterySystemKind.Pack && (tenant === 'northvolt' || tenant === 'epiroc')
}
