import { FormEvent, Reducer, useReducer } from 'react'

/**
 * props that every component should implement in order to be able
 * to use getFieldPropsByName returned by useForm
 */
export type UseFormFieldProps<Value> = {
  name: string
  error?: Error | string | false | null
  value?: Value | null
  onChange?: (value: Value) => void
  onBlur?: () => void
}

export type UseFormErrors<FormData extends { [key: string]: unknown }> = Partial<{
  [key in keyof FormData]: string
}>

export type UseFormTouched<FormData extends { [key: string]: unknown }> = Partial<{
  [key in keyof FormData]?: boolean
}>

export type UseForm<FormData extends { [key: string]: unknown }> = {
  initialValues: FormData
  onValidate: (formData: FormData) => UseFormErrors<FormData>
  /**
   * invoked when the <form> submits
   * if throws an error that value will be in submitError
   */
  onSubmit?: (formData: FormData, ev: FormEvent<HTMLFormElement>) => Promise<void> | void
  /**
   * invoked when any FormData value changes
   */
  onChange?: <FieldName extends keyof FormData>(
    fieldName: FieldName,
    formData: FormData,
    formErrors: UseFormErrors<FormData>,
  ) => Promise<void> | void
  /**
   * invoked when any FormData field blurs
   */
  onBlur?: <FieldName extends keyof FormData>(
    fieldName: FieldName,
    formData: FormData,
    formErrors: UseFormErrors<FormData>,
  ) => Promise<void> | void
}

type ReducerState<FormData extends { [key: string]: unknown }> = {
  values: FormData
  errors: UseFormErrors<FormData>
  touched: UseFormTouched<FormData>
  isSubmitting: boolean
  formHasErrors: boolean
  submitError: null | Error
}

type ReducerActions<FormData extends { [key: string]: unknown }> =
  | { type: 'SET_ERRORS'; payload: UseFormErrors<FormData> }
  | { type: 'SET_VALUES'; payload: FormData }
  | { type: 'SET_INPUT_TOUCHED'; payload: keyof FormData }
  | { type: 'ON_SUBMIT' }
  | { type: 'SUBMIT_FAILURE' }
  | { type: 'SUBMIT_SUCCESS' }
  | { type: 'SUBMIT_FAILED'; payload: Error }

function reducer<FormData extends { [key: string]: unknown }>(
  state: ReducerState<FormData>,
  action: ReducerActions<FormData>,
): ReducerState<FormData> {
  switch (action.type) {
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
        formHasErrors: Object.keys(action.payload).some((error) => !!action.payload[error]),
      }
    case 'SET_VALUES':
      return {
        ...state,
        values: action.payload,
      }
    case 'SET_INPUT_TOUCHED':
      return {
        ...state,
        touched: {
          ...state.touched,
          [action.payload]: true,
        },
      }
    case 'ON_SUBMIT':
      // marks all fields as touched in order to show field-errors
      const touched: Partial<{ [key in keyof FormData]: boolean }> = {}
      Object.keys(state.values).forEach((key: keyof FormData) => {
        touched[key] = true
      })

      return {
        ...state,
        isSubmitting: true,
        submitError: null,
        touched,
      }
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      }
    case 'SUBMIT_FAILED':
      return {
        ...state,
        isSubmitting: false,
        submitError: action.payload,
      }
    case 'SUBMIT_FAILURE':
      return {
        ...state,
        isSubmitting: false,
      }
    default:
      return state
  }
}

export function useForm<FormData extends { [key: string]: any }>(props: UseForm<FormData>) {
  const [state, dispatch] = useReducer<Reducer<ReducerState<FormData>, ReducerActions<FormData>>>(
    reducer,
    {
      values: props.initialValues,
      errors: {},
      touched: {},
      isSubmitting: false,
      formHasErrors: false,
      submitError: null,
    },
  )

  const onChange = <FieldName extends keyof FormData>(name: FieldName) => {
    return (value: FormData[FieldName]) => {
      const newValues = {
        ...state.values,
        [name]: value,
      }
      dispatch({
        type: 'SET_VALUES',
        payload: newValues,
      })
      const errors = props.onValidate(newValues)
      dispatch({
        type: 'SET_ERRORS',
        payload: errors,
      })
      props.onChange?.(
        name,
        {
          ...state.values,
          [name]: value,
        },
        errors,
      )
    }
  }

  const onBlur = (name: keyof FormData) => {
    return () => {
      dispatch({
        type: 'SET_INPUT_TOUCHED',
        payload: name,
      })
      const errors = props.onValidate(state.values)
      dispatch({
        type: 'SET_ERRORS',
        payload: errors,
      })
      props.onBlur?.(name, state.values, errors)
    }
  }

  const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (props.onSubmit) {
      dispatch({
        type: 'ON_SUBMIT',
      })
      if (state.formHasErrors) {
        dispatch({ type: 'SUBMIT_FAILURE' })
      } else {
        try {
          await props.onSubmit(state.values, ev)
          dispatch({ type: 'SUBMIT_SUCCESS' })
        } catch (error) {
          dispatch({
            type: 'SUBMIT_FAILED',
            payload: error instanceof Error ? error : new Error(String(error)),
          })
        }
      }
    }
  }

  function getFieldPropsByName<T extends keyof FormData>(fieldName: T) {
    return {
      name: fieldName as string,
      // only return errors if the field has been touched
      // submitting a form "touches" all the fields in it
      error:
        state.touched[fieldName] &&
        (state.errors[fieldName] as UseFormFieldProps<FormData[T]>['error']),
      value: state.values[fieldName] as FormData[T],
      onChange: onChange(fieldName) as (value: FormData[T]) => void,
      onBlur: onBlur(fieldName),
    }
  }

  return {
    onSubmit,
    getFieldPropsByName,
    ...state,
  }
}
