import { faShareNodes } from '@fortawesome/pro-light-svg-icons'
import { Button } from '@northvolt/form'
import { getSPTSystemURL } from '../../utils/getSystemURL'

export function SPTLink({ id, includeTitle }: { id: string; includeTitle: boolean }) {
  const onClick = () => {
    window.open(getSPTSystemURL(id), '_blank')
  }

  return includeTitle ? (
    <Button onClick={onClick} size="small" style="text" suffix={faShareNodes}>
      Product Tree
    </Button>
  ) : (
    <Button infix={faShareNodes} onClick={onClick} size="small" style="text" />
  )
}
