import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGraphQLContext } from './GraphQLProvider'
import { NVGraphQLNetworkError, NVGraphQLResponseError } from './NVGraphQLError'
import { MutationLoading, MutationResult, QueryType, VariablesType } from './types'

export function useGQLMutation<TResult, TVariables extends VariablesType>(
  mutation: QueryType | string,
) {
  const { graphQLFetcher } = useGraphQLContext()
  const [result, setResult] = useState<MutationLoading | MutationResult<TResult> | null>(null)

  const reset = useCallback(() => {
    setResult(null)
  }, [])

  useEffect(() => {
    setResult(null)
  }, [mutation])

  const mutationFn = useCallback(
    async (variables: TVariables): Promise<MutationResult<TResult>> => {
      let result: MutationResult<TResult>
      try {
        setResult({
          state: 'loading',
        })
        const response = await graphQLFetcher(mutation.toString(), variables)
        if (response.errors != null) {
          result = {
            state: 'error',
            // you might get data even if you get errors
            // for example when running multiple mutations in a single request
            data: (response.data as Partial<TResult> | null) ?? null,
            errors: response.errors,
          }
        } else {
          result = {
            state: 'success',
            data: response.data as TResult,
          }
        }
      } catch (err) {
        if (err instanceof NVGraphQLNetworkError || Array.isArray(err)) {
          result = {
            state: 'error',
            data: null,
            errors: err as (NVGraphQLNetworkError | NVGraphQLResponseError)[],
          }
        } else {
          result = {
            state: 'error',
            data: null,
            errors: [new NVGraphQLResponseError({ message: String(err) })],
          }
        }
      }
      setResult(result)
      return result
    },
    [mutation, graphQLFetcher],
  )

  return useMemo(() => {
    return {
      mutation: mutationFn,
      result,
      reset,
    }
  }, [mutationFn, result, reset])
}
