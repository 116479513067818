import { ErrorBoundary } from './ErrorBoundary'
import { ToastContainer } from './ToastContainer'

export function ErrorHandlingContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <ToastContainer />
      <ErrorBoundary title="Unexpected Error">{children}</ErrorBoundary>
    </div>
  )
}
