import { SystemsByIdsQuery, SystemsByIdsQueryVariables, useGQL } from '@northvolt/gql'
import { useMemo, useState } from 'react'
import { SystemCard } from '../SystemList/SystemCard'
import { systemsByIdsQuery } from '../systemQueries'
import { SystemType } from '../types'
import { ShowAll } from './ShowAll'
import { getRecent } from './recentStorage'

export const RecentlyViewed = ({ type }: { type: SystemType }) => {
  const ids = useMemo(() => {
    return {
      ids: getRecent(type),
    }
  }, [type])

  const {
    data: {
      systemsByIDs: { items: systems },
    },
  } = useGQL<SystemsByIdsQuery, SystemsByIdsQueryVariables>(systemsByIdsQuery, ids, {
    refreshInterval: 5000,
  })

  const [index] = useState(0)
  const [visible, setVisible] = useState(3)

  return (
    <>
      {systems.length === 0 ? null : ( // hide element if there are no recently viewed elements
        <div className="xl:w-9/12 w-11/12 mx-auto">
          <div className="hidden lg:block">
            <div className="flex justify-between">
              <div className="mb-6">
                <h1 className="text-3xl font-semibold">
                  Recently viewed{' '}
                  {type === 'voltpacks' ? 'Voltpacks' : type === 'cores' ? 'Cores' : ''}
                </h1>
              </div>
              <div>
                <ShowAll recentlyViewedSystems={systems} />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-3 mt-4">
              {systems.slice(index, visible).map((system) => {
                return <SystemCard key={system.id} system={system} />
              })}
            </div>
          </div>

          <div className="block lg:hidden">
            <h1 className="mb-6 text-3xl font-semibold">
              Recently viewed {type === 'voltpacks' ? 'Voltpacks' : type === 'cores' ? 'Cores' : ''}
            </h1>

            {systems.slice(index, visible).map((system) => {
              return <SystemCard key={system.id} system={system} />
            })}

            {visible < systems.length ? (
              <div className="text-center">
                <button
                  className="transition ease-in duration-300 font-normal bg-white border-2 border-black hover:bg-black text-black hover:text-white mx-auto px-5 py-3 text-center my-12 focus:outline-none"
                  onClick={() => setVisible((prevValue) => prevValue + 5)}
                >
                  Load more
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}
