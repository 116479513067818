import { captureException } from '@northvolt/error-handling'
import jwtDecode from 'jwt-decode'
import { JWT } from '../types'

export function createCookieString(
  name: string,
  value: string,
  expiration: Date,
  domain: string,
  secure: boolean,
) {
  return `${name}=${value};expires=${expiration.toUTCString()};domain=${domain};path=/;${secure}`
}

export function setCookie(name: string, value: string, expiration: Date, domain: string) {
  let cookieSecurity = true

  if (window.location.hostname === 'localhost') {
    cookieSecurity = false
  }

  return (document.cookie = createCookieString(name, value, expiration, domain, cookieSecurity))
}

export function getCookieFromString(name: string, cookie: string) {
  const nameEQ = `${name}=`
  const cookies = cookie.split(';')

  for (let cookie of cookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }
  return null
}

export function getCookie(name: string) {
  return getCookieFromString(name, document.cookie)
}

export const eraseCookie = (name: string, domain: string) => {
  // set expiration date in the past effectively deletes the cookie
  setCookie(name, '', new Date(0), domain)
}

export function getJWTFromCookie(cookie: string): JWT | null {
  try {
    const tokenString = getCookie(cookie)
    if (tokenString == null) {
      return null
    }
    const decoded = jwtDecode(tokenString) as any

    if (!('exp' in decoded)) {
      throw new Error(`decoded JWT ${cookie} does not contain any expiration!`)
    }

    const expires = new Date(decoded.exp * 1000)
    return {
      tokenString,
      decodedContents: decoded,
      expires,
    }
  } catch (err) {
    captureException(err)
    return null
  }
}
