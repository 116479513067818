import { differenceInDays, differenceInHours, differenceInMinutes, formatDistance } from 'date-fns'
import { ConnectivityInformation, ConnectivityState } from './types'

export function getConnectivityInformation(lastObserved: Date | null): ConnectivityInformation {
  const now = new Date()
  const lastSeen =
    lastObserved == null ? null : formatDistance(lastObserved, now, { addSuffix: true })
  if (lastObserved == null) {
    return { state: ConnectivityState.Unknown, lastSeen }
  }
  const minutesSinceSeen = differenceInMinutes(now, lastObserved)

  if (minutesSinceSeen <= 2) {
    return { state: ConnectivityState.Online, lastSeen }
  }
  const hoursSinceSeen = differenceInHours(now, lastObserved)

  if (hoursSinceSeen <= 24) {
    return { state: ConnectivityState.RecentlyOnline, lastSeen }
  }
  const daysSinceSeen = differenceInDays(now, lastObserved)

  if (daysSinceSeen <= 14) {
    return { state: ConnectivityState.Offline, lastSeen }
  }
  return { state: ConnectivityState.OfflineLongTime, lastSeen }
}
