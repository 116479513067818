import { StateOfChargeFragment } from '@northvolt/gql'
import { getStateOfChargeVisibility } from './getStateOfChargeVisibility'

export function getStateOfCharge(system: StateOfChargeFragment, isPowerUser?: boolean) {
  const { shouldDisplayOperationalSoC, shouldDisplayNominalSoC } = getStateOfChargeVisibility(
    system,
    isPowerUser,
  )

  if (system.state?.stateOfCharge == null && system.state?.operationalStateOfCharge != null) {
    return system.state.operationalStateOfCharge
  }
  if (shouldDisplayNominalSoC) {
    return system.state?.stateOfCharge
  }
  if (shouldDisplayOperationalSoC) {
    return system.state?.operationalStateOfCharge
  }

  return null
}
