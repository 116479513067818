import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import clsx from 'clsx'
import React from 'react'
import { StandardIcon, StandardIconProps } from '../Icon'
import { SizeValue } from '../useSizeScreen'
import { getBoxElementTextSizeClassName } from './getBoxElementTextSizeClassName'

/**
 * if affix is StandardIconProps will render a StandardIcon with said props
 * if affix is IconDefinition will render a <Standard icon={affix} />
 * if affix is React.ReactNode will render as is, user is responsible for paddings/margins
 */
export type BoxElementAffixProp = StandardIconProps | React.ReactNode | IconDefinition

/**
 * Renders an affix (suffix/prefix) of a Box
 */
export function BoxElementAffix({
  affix,
  size,
  className,
}: {
  affix?: BoxElementAffixProp | null
  size: SizeValue
  className?: string
}) {
  if (affix == null || typeof affix === 'boolean') {
    return null
  }
  let ret: React.ReactNode
  let onClick: undefined | ((ev: any) => void)
  if (typeof affix === 'object' && 'icon' in affix) {
    if ('iconName' in affix) {
      // IconDefinition
      ret = <StandardIcon icon={affix} size={size} />
    } else {
      // StandardIconProps
      onClick = affix.onClick
      ret = <StandardIcon size={size} {...affix} onClick={undefined} />
    }
  } else {
    // React.ReactNode
    ret = affix
  }
  return (
    <div className={clsx(className, 'flex items-center')} onClick={onClick}>
      {ret}
      <span className={getBoxElementTextSizeClassName(size)}>
        {
          // makes the element the same height as BoxElement
          '\u200B'
        }
      </span>
    </div>
  )
}
