import { useMemo, useState } from 'react'
import { Input } from '../Input'
import { Option, OptionsItem } from '../Options'
import { Tags } from '../Tag'
import { InputState } from '../types'
import { FormFieldWrapper, UseFormFieldProps } from '../useForm'
import { Size, useSizeScreen } from '../useSizeScreen'

export type InputMultiselectProps = {
  autocomplete: string[]
  label?: string
  size?: Size
  placeholder?: string
  className?: string
  tabIndex?: number
  required?: boolean
  state?: InputState
  disabled?: boolean
  addMessage?: string
  alreadySelectedMessage?: string
  autoFocus?: boolean
} & UseFormFieldProps<string[]>

export function InputMultiselect({
  name,
  label,
  required,
  error,
  value,
  placeholder,
  size,
  className,
  tabIndex,
  autocomplete,
  disabled,
  onChange,
  onBlur,
  state,
  addMessage,
  alreadySelectedMessage,
  autoFocus,
}: InputMultiselectProps) {
  const [_inputValue, setInputValue] = useState<null | string>(null)
  const inputValue = useMemo(() => {
    return _inputValue?.replace(/\s+/g, ' ').trim() ?? null
  }, [_inputValue])

  const addValue = (newValue?: OptionsItem | null) => {
    let v: string | null
    if (newValue == null) {
      v = inputValue
    } else if (typeof newValue === 'string') {
      v = newValue
    } else {
      v = newValue.value
    }
    if (v == null || v.length === 0) {
      return
    }
    let values = value ?? []
    if (!values.includes(v)) {
      values = [...values, v]
    }
    onChange?.(values)
    setInputValue(null)
  }

  const sizeScreen = useSizeScreen(size)
  const isDisabled = disabled || state === 'loading'

  let addTagOption: React.ReactNode = (
    <Option as="Combobox" item={null} onClick={addValue} selected={false} size={sizeScreen}>
      {addMessage ?? 'Create new tag:'}
      {` "${inputValue}"`}
    </Option>
  )
  if (value?.includes(inputValue ?? '')) {
    addTagOption = (
      <Option
        as="Combobox"
        className="text-gray-600"
        item={null}
        key="add-tag"
        selected={false}
        size={sizeScreen}
      >
        {alreadySelectedMessage ?? 'Tag already selected:'}
        {` "${inputValue}"`}
      </Option>
    )
  }
  if (autocomplete.includes(inputValue ?? '')) {
    addTagOption = null
  }

  return (
    <FormFieldWrapper
      as="label"
      className={className}
      error={null}
      label={label}
      required={required ?? null}
      size={sizeScreen}
    >
      {(value?.length ?? 0) > 0 && (
        <Tags
          disabled={isDisabled}
          onRemove={(tag: string) => {
            const filteredList = value?.filter((t) => t !== tag) ?? []
            onChange?.(filteredList)
          }}
          size={sizeScreen}
          tags={value ?? []}
        />
      )}
      <Input
        autoFocus={autoFocus}
        autocomplete={[
          addTagOption,
          ...autocomplete.filter((v) => {
            return !value?.includes(v)
          }),
        ]}
        disabled={isDisabled}
        error={error}
        name={name}
        noResultsMessage={null}
        onAutocompleteSelection={addValue}
        onBlur={onBlur}
        onChange={setInputValue}
        placeholder={placeholder}
        required={required}
        size={sizeScreen}
        state={state}
        tabIndex={tabIndex}
        value={_inputValue}
      />
    </FormFieldWrapper>
  )
}
