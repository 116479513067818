import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'
import React, { ForwardedRef, MouseEventHandler } from 'react'
import { BoxElementAffix, BoxElementAffixProp, getBoxElementTextSizeClassName } from '../Box'
import { Size, useSizeScreen } from '../useSizeScreen'

type ConditionalProps =
  | {
      children?: React.ReactNode
      infix?: never
      /**
       * Used when button is set specifically to be wider than what it would normally grow to
       * Sets the textAlign style of the button element, defaults to 'center'
       */
      textAlignment?: 'left' | 'center' | 'right'
    }
  | {
      children?: never
      /**
       * used to render an icon centered within the button
       * if provided children is not used
       */
      infix?: BoxElementAffixProp
      textAlignment?: never
    }

export type ButtonProps = {
  style?:
    | 'primary'
    | 'secondary'
    | 'text'
    | 'text-info'
    | 'text-success'
    | 'text-warning'
    | 'text-error'
    | 'critical'
    | 'warning'
    | 'success'
    | 'flatPrimary'
    | 'breadcrumbBlack'
    | 'breadcrumbGreen'
    | 'filterDefault'
    | 'filterActive'
  /**
   * If type is "div" the Button is rendered as a <div>. This is useful if you were to put a button
   * inside a <Menu.Button> from headlessui for example
   * (<buttons><button>Text</button></button> is not allowed by the HTML spec)
   * If type is absent or "button" the type is <button type="button">
   * If type is "submit" the type is <button type="submit">, this is useful when placing buttons
   * inside <form>
   * If type is "reset" the type is <button type="reset">, this is useful when placing buttons
   * inside <form>
   * If type is "label" the button is rendered as a <label> element, this is useful when using
   * labels to render a button instead of a <input type="file"> element, using htmlFor to trigger
   * the file selector.
   */
  type?: 'button' | 'reset' | 'submit' | 'div' | 'label'
  htmlFor?: string
  size?: Size
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  className?: string
  tabIndex?: number
  children?: React.ReactNode
  prefix?: BoxElementAffixProp
  suffix?: BoxElementAffixProp
  /** Disables the visual effect when pressing the button */
  disableActiveStyle?: boolean
  testId?: string
  textEllipsis?: boolean
} & ConditionalProps

function ButtonInner(
  {
    className,
    type = 'button',
    htmlFor,
    style = 'primary',
    size,
    children,
    onClick,
    disabled,
    tabIndex,
    prefix,
    suffix,
    infix,
    disableActiveStyle,
    textAlignment,
    testId,
    textEllipsis,
  }: ButtonProps,
  forwardedRef?: ForwardedRef<HTMLDivElement & HTMLButtonElement & HTMLLabelElement>,
) {
  const sizeScreen = useSizeScreen(size)
  let BaseComponent: 'button' | 'div' | 'label' = 'button'
  if (type === 'div') {
    BaseComponent = 'div'
  }
  if (type === 'label') {
    BaseComponent = 'label'
  }

  // customer portal
  const breadcrumbGreen = style === 'breadcrumbGreen'
  const breadcrumbBlack = style === 'breadcrumbBlack'
  const flatPrimary = style === 'flatPrimary'
  const flat = breadcrumbGreen || breadcrumbBlack || flatPrimary

  // we use these styles only in Filter component
  const filterDefault = style === 'filterDefault' // when there is no option selected
  const filterActive = style === 'filterActive' // when there is at least 1 option selected

  return (
    <BaseComponent
      className={clsx(
        className,
        `group select-none focus:outline-none outline-none flex items-center justify-center tracking-wide`,
        infix == null && flat && 'px-3',
        infix == null &&
          !flat && {
            'px-3': sizeScreen === 'small',
            'px-4': sizeScreen === 'medium',
            'px-6': sizeScreen === 'large',
          },
        infix == null && {
          'space-x-2': sizeScreen === 'small',
          'space-x-3': sizeScreen === 'medium',
          'space-x-4': sizeScreen === 'large',
        },
        disabled
          ? {
              'bg-gray-300 text-gray-700 border-gray-300': style === 'primary',
              'bg-gray-100 border-gray-400 text-gray-500': style === 'secondary',
              'bg-transparent text-gray-500 border-transparent': [
                'text',
                'text-info',
                'text-success',
                'text-warning',
                'text-error',
              ].includes(style),
              'bg-green-25 text-white border-green-25': style === 'success',
              'bg-orange-25 text-white border-orange-25': style === 'warning',
              'bg-red-25 text-white border-red-25': style === 'critical',
            }
          : {
              'bg-NvTeal-300 hover:bg-NvBlue-300 text-white border-NvTeal-300 hover:border-NvBlue-300':
                style === 'primary',
              'bg-transparent hover:bg-NvTeal-300/10 text-NvTeal-300 border-NvTeal-300/80':
                style === 'secondary',
              'bg-transparent hover:bg-NvTeal-300/10 text-NvTeal-300 border-transparent':
                style === 'text',
              'bg-transparent hover:bg-blue-200/10 active:bg-blue-200/30 text-blue-200 border-transparent':
                style === 'text-info',
              'bg-transparent hover:bg-green-200/10 active:bg-green-200/30 text-green-200 border-transparent':
                style === 'text-success',
              'bg-transparent hover:bg-orange-200/10 active:bg-orange-200/30 text-orange-200 border-transparent':
                style === 'text-warning',
              'bg-transparent hover:bg-red-200/10 active:bg-red-200/30 text-red-200 border-transparent':
                style === 'text-error',
              'bg-green-100 hover:bg-green-200 text-white border-green-100 hover:border-green-200':
                style === 'success',
              'bg-orange-100 hover:bg-orange-200 text-white border-orange-100 hover:border-orange-200':
                style === 'warning',
              'bg-red-100 hover:bg-red-200 text-white border-red-100 hover:border-red-200':
                style === 'critical',
            },
        !disabled &&
          !disableActiveStyle && {
            'active:bg-NvTeal-200 active:border-NvTeal-200': style === 'primary',
            'active:bg-NvTeal-300/30': style === 'secondary',
            'active:bg-NvTeal-300/30 ': style === 'text',
            'active:bg-blue-200/30': style === 'text-info',
            'active:bg-green-200/30': style === 'text-success',
            'active:bg-orange-200/30': style === 'text-warning',
            'active:bg-red-200/30': style === 'text-error',
            'active:bg-green-50 active:border-green-50': style === 'success',
            'active:bg-orange-50 active:border-orange-50': style === 'warning',
            'active:bg-red-50 active:border-red-50': style === 'critical',
          },
        {
          // other options
          'cursor-not-allowed': disabled || breadcrumbGreen,
          'cursor-pointer transition': !disabled || !breadcrumbGreen,
          'rounded font-semibold': !flat,
          border: !flat,

          // customer portal
          'font-normal text-sm h-10 mr-2 w-44 md:w-48': flatPrimary,
          'bg-black hover:bg-NvGreen-200 text-white': flatPrimary && !disabled,
          'active:bg-NvGreen-200 active:opacity-80':
            (breadcrumbBlack || flatPrimary) && !disabled && !disableActiveStyle,
          'bg-gray-600 text-gray-200': flatPrimary && disabled,
          'font-semibold text-sm h-8': breadcrumbBlack || breadcrumbGreen,
          'px-2 bg-black hover:bg-NvGreen-200  text-white ': breadcrumbBlack,
          'px-4 bg-NvGreen-200 text-white': breadcrumbGreen,
          'w-8': breadcrumbBlack && infix != null,
        },
        // filter
        disabled
          ? {
              'bg-transparent text-gray-500 border-gray-500/60': filterDefault,
              'bg-gray-400/30 text-gray-600 border-transparent': filterActive,
            }
          : {
              'bg-transparent hover:bg-gray-400/30 text-gray-800 border-gray-500': filterDefault,
              'bg-NvGreen-100/30 hover:bg-NvGreen-100/60 text-NvGreen-400 border-transparent':
                filterActive,
            },
      )}
      disabled={disabled}
      htmlFor={htmlFor}
      onClick={
        disabled
          ? undefined
          : (onClick as MouseEventHandler<HTMLButtonElement | HTMLDivElement | HTMLLabelElement>)
      }
      ref={forwardedRef}
      style={{
        WebkitTapHighlightColor: 'transparent',
      }}
      tabIndex={tabIndex}
      {...(type !== 'div' && type !== 'label' ? { type } : {})}
      {...getTestId(testId)}
    >
      {prefix != null && (
        <BoxElementAffix affix={prefix} className={clsx('flex-0')} size={sizeScreen} />
      )}
      {infix != null ? (
        <div
          className={clsx('flex flex-0 items-center justify-center', {
            // this needs to match the height of the button which is defined
            // by the font-size/line-height in getBoxElementTextSizeClassName
            'min-w-8': sizeScreen === 'small',
            'min-w-10': sizeScreen === 'medium',
            'min-w-12': sizeScreen === 'large',
          })}
        >
          <BoxElementAffix affix={infix} size={sizeScreen} />
        </div>
      ) : (
        <span
          className={clsx(
            !flat && getBoxElementTextSizeClassName(sizeScreen),
            { 'text-ellipsis overflow-hidden whitespace-nowrap': textEllipsis },
            {
              'text-left flex-1': textAlignment === 'left',
              'text-center': textAlignment === 'center' || textAlignment == null,
              'text-right flex-1': textAlignment === 'right',
            },
          )}
        >
          {children}
        </span>
      )}
      {suffix != null && (
        <BoxElementAffix affix={suffix} className={clsx('flex-0')} size={sizeScreen} />
      )}
    </BaseComponent>
  )
}

export const Button = React.forwardRef(ButtonInner)
