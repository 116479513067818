import { faGlobe, faNetworkWired, faPlug, faTablet } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuthenticationContext } from '@northvolt/auth'
import { config } from '@northvolt/config'
import { Button, Menu, MenuItemProps } from '@northvolt/form'
import GQL from '../../images/gql.png'
import mender from '../../images/mender.png'
import CopyToClipboardText from '../Common/CopyToClipboard/CopyToClipboard'

function parseMacAddressParts(id: string) {
  if (!/^eg(-[A-z0-9]{2}){6}$/.test(id)) {
    return null
  }

  return id.split('-')
}

function generateIPv6(id: string, isDevelopment: boolean) {
  const parts = parseMacAddressParts(id)

  if (!parts) {
    return null
  }

  const [, a0, a1, b0, b1, c0, c1] = parts

  return `fd3b:d3b5:576:${isDevelopment ? '2' : '1'}:${a0}${a1}:${b0}${b1}:${c0}${c1}:0`
}

function generateMac(id: string) {
  const parts = parseMacAddressParts(id)

  if (!parts) {
    return null
  }

  const [, a0, a1, b0, b1, c0, c1] = parts

  return `${a0}:${a1}:${b0}:${b1}:${c0}:${c1}`
}

const CustomIcon = ({ iconUrl }: { iconUrl: string }) => (
  <span
    className="w-4 h-4 mx-2.5 opacity-70"
    style={{
      backgroundImage: `url(${iconUrl})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      boxSizing: 'content-box',
      display: 'inline-block',
      verticalAlign: '-0.125em',
    }}
  />
)

export function Connect({
  coreId,
  id,
  includeTitle,
  shouldDisplayRemoteAccessLink,
}: {
  coreId: string
  id: string | null
  includeTitle: boolean
  shouldDisplayRemoteAccessLink: boolean
}) {
  const { user } = useAuthenticationContext()

  const mac = generateMac(id ?? '')
  const ipv6 = generateIPv6(id ?? '', config.REACT_APP_ENVIRONMENT === 'development')

  let list: MenuItemProps[] = []

  if (mac) {
    list.push({
      children: (
        <>
          <FontAwesomeIcon className="ml-2 mr-3 text-gray-700" icon={faNetworkWired} />
          <CopyToClipboardText text={mac} />
        </>
      ),
      onClick: () => {
        // nop
        return undefined
      },
    })
  }

  if (ipv6) {
    list.push({
      children: (
        <>
          <FontAwesomeIcon className="ml-2 mr-3 text-gray-700" icon={faNetworkWired} />
          <CopyToClipboardText text={ipv6} />
        </>
      ),
      onClick: () => {
        // nop
        return undefined
      },
    })
  }

  if (shouldDisplayRemoteAccessLink) {
    list.push({
      children: (
        <>
          <FontAwesomeIcon className="ml-2 mr-3 text-gray-700" icon={faGlobe} />
          Remote Access
        </>
      ),
      link: `${config.REACT_APP_BASE_WRAPPER_URL}/rae/${coreId}/batteries`,
    })
  }

  if (user.tenant === 'northvolt') {
    if (list.length > 0) {
      list.push({
        separator: true,
      })
    }

    if (ipv6) {
      list = [
        ...list,
        {
          children: (
            <>
              <FontAwesomeIcon className="mx-3 text-gray-700" icon={faTablet} />
              Local UI
            </>
          ),
          link: `http://[${ipv6}]`,
        },
        {
          children: (
            <>
              <FontAwesomeIcon className="mx-3 text-gray-700" icon={faTablet} />
              Mapper Errors
            </>
          ),
          link: `http://[${ipv6}]:8080/errors`,
        },
        {
          children: (
            <>
              <FontAwesomeIcon className="mx-3 text-gray-700" icon={faTablet} />
              Mapper CAN Stats
            </>
          ),
          link: `http://[${ipv6}]:8080/can-stats`,
        },
        {
          children: (
            <>
              <FontAwesomeIcon className="mx-3 text-gray-700" icon={faTablet} />
              Mapper Publish Stats
            </>
          ),
          link: `http://[${ipv6}]:8080/publish-stats`,
        },
        {
          children: (
            <>
              <FontAwesomeIcon className="mx-3 text-gray-700" icon={faTablet} />
              Mapper Last Published Kinesis Message
            </>
          ),
          link: `http://[${ipv6}]:8080/last-kinesis-message`,
        },
        {
          children: (
            <>
              <CustomIcon iconUrl={GQL} />
              GraphQL Playground
            </>
          ),
          link: `http://[${ipv6}]:8081`,
        },
      ]
    }

    if (mac) {
      list.push({
        children: (
          <>
            <CustomIcon iconUrl={mender} />
            Hosted Mender UI
          </>
        ),
        link: `https://hosted.mender.io/ui/#/devices?mac=${mac}`,
      })
    }
  }

  const menuButton = includeTitle ? (
    <Button disableActiveStyle size="small" style="text" suffix={faPlug}>
      Connect
    </Button>
  ) : (
    <Button disableActiveStyle infix={faPlug} size="small" style="text" />
  )

  return <Menu alignMenu="right" button={menuButton} className="mr-2" list={list} />
}
