import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'

type TableCellProps = {
  children: React.ReactNode
  verticalPadding?: 'none' | 'small' | 'medium' | 'large'
  highlight?: boolean
  className?: string
  testId?: string
} & React.ComponentProps<'td'>

export function TableCell({
  children,
  verticalPadding,
  highlight,
  className,
  testId,
  ...restProps
}: TableCellProps) {
  return (
    <td
      {...getTestId(testId)}
      className={clsx(
        'text-base px-4',
        {
          'py-0': verticalPadding === 'none',
          'py-2': verticalPadding === 'small',
          'py-4': verticalPadding === 'medium' || verticalPadding == null,
          'py-8': verticalPadding === 'large',
          'font-medium text-black': highlight === true,
          'text-gray-900': highlight !== true,
        },
        highlight,
        className,
      )}
      {...restProps}
    >
      {children}
    </td>
  )
}
