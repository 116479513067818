import { faSpider } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RolesEnum, useAuthenticationContext } from '@northvolt/auth'
import {
  ConnectivityInformationBadge,
  getSystemConnectivityInformation,
} from '@northvolt/connectivity-state'
import { DcirStatusBadge } from '@northvolt/dcir'
import { Tag } from '@northvolt/form'
import { BatterySystemKind, SystemFragment } from '@northvolt/gql'
import {
  Charge,
  compareSoftwareVersions,
  getStateOfCharge,
  getSystemTypeDisplayName,
} from '@northvolt/system-utils'
import { TableCell, TableRow } from '@northvolt/table'
import { Tenant } from '@northvolt/tenant-metadata'
import clsx from 'clsx'
import { getSystemImage } from '../../utils/getSystemImage'
import { getSystemURL } from '../../utils/getSystemURL'
import { hasSPTIntegration } from '../../utils/hasSPTIntegration'
import { EditModalButton } from '../Management'
import { saveRecent } from '../RecentlyViewed'
import { Connect } from './Connect'
import { SPTLink } from './SPTLink'

type SystemListRowProps = {
  system: SystemFragment
}

type CellWithLinkProps = {
  children: React.ReactNode
  className?: string
} & SystemListRowProps

function CellWithLink({ system, children, className }: CellWithLinkProps) {
  const onClick = () => {
    saveRecent(system, system.kind === BatterySystemKind.Subpack ? 'cores' : 'voltpacks')
    saveRecent(system, 'all')
  }
  return (
    <TableCell className={clsx('h-0', className)} verticalPadding="none">
      <a
        className="h-full flex items-center py-4 px-4 -mx-4"
        href={getSystemURL(system.id)}
        onClick={onClick}
      >
        {children}
      </a>
    </TableCell>
  )
}

export function SystemListRow({ system }: SystemListRowProps) {
  const {
    hasRole,
    user: { tenant },
  } = useAuthenticationContext()

  const isPowerUser =
    hasRole([
      RolesEnum.ROOT,
      RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION,
      RolesEnum.BATTERY_DIAGNOSTICS_ADMIN,
      RolesEnum.BATTERY_DIAGNOSTICS_WRITER,
    ]) || tenant === 'northvolt'

  const isAdminUser =
    hasRole([RolesEnum.ROOT, RolesEnum.BATTERY_DIAGNOSTICS_ADMIN]) || tenant === 'northvolt'

  const isSuperAdminUser =
    hasRole([RolesEnum.ROOT, RolesEnum.BATTERY_DIAGNOSTICS_ADMIN]) && tenant === 'northvolt'

  const spiderVersion: string = (system.state?.softwareVersions ?? []).reduce((prev, curr) => {
    if (curr.software === 'Spider') {
      return curr.version
    }
    return prev
  }, '0.0.0')

  const shouldDisplayRemoteAccessLink =
    compareSoftwareVersions('1.6.0', spiderVersion) <= 0 && isSuperAdminUser

  return (
    <TableRow className="last:border-none">
      <CellWithLink className="lg:max-w-72" system={system}>
        <div className="-ml-4 flex items-center">
          {getSystemImage('table', system?.kind ?? BatterySystemKind.Subpack)}
          <div>
            <div className="font-bold text-sm text-gray-900">
              {system.displayName ? system.displayName : system.id}
              {system.kind === BatterySystemKind.Subpack && (
                <DcirStatusBadge coreId={system.id} tenant={system.meta.tenant} />
              )}
            </div>
            <div className="text-sm text-gray-500">{system.id}</div>
          </div>
        </div>
      </CellWithLink>

      <CellWithLink className="lg:max-w-72 xl:max-w-auto" system={system}>
        <div className="flex items-center gap-2 flex-wrap">
          {system.tags.map((tag, idx) => (
            <Tag key={`${system.id}-tag-${idx}`}>{tag}</Tag>
          ))}
          {isAdminUser && (
            <Tag key={`${system.id}-tag-spider-${system.id}`}>
              <FontAwesomeIcon className="mr-1" icon={faSpider} />
              {spiderVersion}
            </Tag>
          )}
          {tenant === 'northvolt' && <Tag key="tenant">{system.meta?.tenant}</Tag>}
        </div>
      </CellWithLink>

      <CellWithLink system={system}>
        <span className="text-sm text-gray-500">{getSystemTypeDisplayName(system)}</span>
      </CellWithLink>

      <CellWithLink className="whitespace-nowrap" system={system}>
        <Charge charge={getStateOfCharge(system, isPowerUser)} />
      </CellWithLink>

      <CellWithLink system={system}>
        <div className="block">
          <ConnectivityInformationBadge
            connectivity={getSystemConnectivityInformation(system)}
            showLastSeen={true}
          />
        </div>
      </CellWithLink>

      <TableCell verticalPadding="medium">
        <div className="flex items-center justify-end gap-1 flex-nowrap -mr-4">
          {hasSPTIntegration(system.kind, system.meta.tenant as Tenant) && isAdminUser && (
            <SPTLink id={system.id} includeTitle={true} />
          )}
          {system.kind === BatterySystemKind.Subpack && (
            <Connect
              coreId={system.id}
              id={system.computers ? system.computers[0].id : null}
              includeTitle={true}
              shouldDisplayRemoteAccessLink={shouldDisplayRemoteAccessLink}
            />
          )}
          <EditModalButton id={system.id} includeTitle={true} kind={system.kind} size="small" />
        </div>
      </TableCell>
    </TableRow>
  )
}
