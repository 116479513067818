import { Tooltip } from '@northvolt/tooltip'
import clsx from 'clsx'

import dcirData from './dcir.json'

export type DcirHighLow = null | 'low' | 'high'

// We have only tracked data up to core ID VPC8C05-02091. If the increment is _higher_
// than this number, we can safely make the assumption that we have BAK cells and low
// DCIR.
const HIGHEST_AVAILABLE_VPC_ID_INCREMENT = 2091

export const DcirStatusBadge = ({ coreId, tenant }: { coreId: string; tenant: string }) => {
  let status: DcirHighLow = null

  const coreDcir = dcirData.find((core) => core.string_id === coreId)

  if (coreDcir) {
    status = coreDcir.high_low as DcirHighLow
  }

  if (!status && tenant === 'epiroc' && coreId > `VPC8C05-0${HIGHEST_AVAILABLE_VPC_ID_INCREMENT}`) {
    status = 'low'
  }

  if (!status) {
    return null
  }

  return (
    <Tooltip title={`DCIR Status: ${status}`}>
      <div className={'flex items-center'}>
        <span
          className={clsx('inline-block w-2 h-2 ml-2 rounded-full', {
            'bg-NvBlue': status === 'low',
            'bg-NvRed': status === 'high',
          })}
        />
      </div>
    </Tooltip>
  )
}
