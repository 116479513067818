import { abortSignalTimeoutPolyfill } from '@northvolt/polyfills'
import { ReactNode, createContext, useContext, useMemo } from 'react'
import { SWRConfig, useSWRConfig } from 'swr'
import {
  GraphQLErrorResponse,
  NVGraphQLNetworkError,
  NVGraphQLResponseError,
} from './NVGraphQLError'
import { deserializeSWRKey, keyMatchesQuery } from './swrKey'
import {
  GraphQLFetcher,
  GraphQLFetcherResponse,
  OverrideHeaders,
  QueryType,
  VariablesType,
} from './types'

abortSignalTimeoutPolyfill()

export type GraphQLContextValue = {
  graphQLSWRFetcher: <Data extends Record<string, any> = Record<string, any>>(
    args: string,
  ) => Promise<Data>
  graphQLFetcher: <Data extends Record<string, any> = Record<string, any>>(
    query: string,
    variables: VariablesType,
  ) => Promise<Data>
  /**
   * invalidates the SWR cache for all active cache entries that match the provided query
   * forcing refetching of said queries
   * if variables is provided only the queries that match those variables will be invalidated
   */
  revalidateQuery(query: QueryType, variables?: VariablesType): Promise<void>
}

function getOverrideHeaders(): OverrideHeaders | null {
  const ApiKey = localStorage.getItem('apiKey')
  const Integration = localStorage.getItem('integration')

  if (ApiKey && Integration) {
    return {
      ApiKey,
      Integration,
    }
  }

  return null
}

const GraphQLContext = createContext<GraphQLContextValue | undefined>(undefined)

const defaultGraphQLFetcher: GraphQLFetcher = async (
  query: string,
  variables: VariablesType,
  apiUrl: string,
  token: string,
) => {
  let data: GraphQLFetcherResponse['data'] = null
  let errors: GraphQLFetcherResponse['errors'] = []
  let response: Response | null = null

  // check for override headers in local storage. This can be useful for some
  // specific use cases where we want to access the API with static tokens by
  // manually adding override headers as specific named localStorage entries.
  const overrideHeaders = getOverrideHeaders()

  let authHeaders = {}
  if (overrideHeaders) {
    authHeaders = overrideHeaders
  } else if (token) {
    authHeaders = {
      authorization: `Bearer ${token}`,
    }
  }

  try {
    response = await fetch(apiUrl, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
      headers: {
        ...authHeaders,
        accept: '*/*',
        'content-type': 'application/json',
      },
      signal: AbortSignal.timeout(10000),
    })

    if (!response.ok) {
      errors.push(new NVGraphQLNetworkError(response.status, null))
    } else {
      try {
        const responseBody = await response.json()
        if (responseBody != null) {
          data = responseBody.data ?? null
          if (responseBody.errors != null) {
            // graphql api errors
            errors = [
              ...errors,
              ...responseBody.errors.map((gqlError: GraphQLErrorResponse) => {
                return new NVGraphQLResponseError(gqlError)
              }),
            ]
          }
        }
      } catch (e) {
        errors.push(new NVGraphQLNetworkError('invalid-body', e))
      }
    }
  } catch (e) {
    if (e instanceof Error && e.name === 'TimeoutError') {
      // see:
      // https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout#examples
      errors.push(new NVGraphQLNetworkError('timeout', e))
    } else {
      errors.push(new NVGraphQLNetworkError('unknown', e))
    }
  }

  return { data, errors }
}

type GraphQLProviderProps = {
  apiUrl: string
  getToken: () => Promise<string> | string
  customGraphQLFetcher?: GraphQLFetcher
  onError?: (errors: (Error | Record<string, any>)[]) => void
  children: ReactNode
}

function GraphQLProviderWithSWR({
  apiUrl,
  getToken,
  customGraphQLFetcher,
  onError,
  children,
}: GraphQLProviderProps) {
  const { cache, mutate } = useSWRConfig()

  const providerValue = useMemo<GraphQLContextValue>(() => {
    const onErrorCb =
      onError ??
      ((errors) => {
        errors.forEach((err) => {
          console.error(err)
          // TODO sentry capture exception here
        })
      })

    const graphQLFetcher = async (query: string, variables: VariablesType): Promise<any> => {
      let response: GraphQLFetcherResponse = {
        data: null,
        errors: [],
      }
      const token = await getToken()
      if (customGraphQLFetcher != null) {
        response = await customGraphQLFetcher(query, variables, apiUrl, token)
      } else {
        response = await defaultGraphQLFetcher(query, variables, apiUrl, token)
      }
      const { data, errors } = response

      if (errors.length > 0) {
        onErrorCb(errors)
        if (data == null) {
          // if we get no data at all and have errors then we want to trigger the React error boundary
          // if we get data AND errors we don't throw the errors
          throw errors
        }
      }

      return data
    }

    return {
      graphQLFetcher,
      graphQLSWRFetcher: async (key: string): Promise<any> => {
        const { query, variables } = deserializeSWRKey(key)
        return graphQLFetcher(query, variables)
      },
      async revalidateQuery(query: QueryType, variables?: VariablesType) {
        const promises = []
        const queryStr = query.toString()
        for (const key of cache.keys()) {
          if (keyMatchesQuery(key, queryStr, variables)) {
            promises.push(mutate(key, undefined, true))
          }
        }
        await Promise.all(promises)
      },
    }
  }, [apiUrl, getToken, customGraphQLFetcher, onError, cache, mutate])

  return <GraphQLContext.Provider value={providerValue}>{children}</GraphQLContext.Provider>
}

type SWRConfigValue = React.ComponentProps<typeof SWRConfig>['value']
export function GraphQLProvider(props: GraphQLProviderProps) {
  const swrConfig = useMemo<SWRConfigValue>(() => {
    // sets the cache provider
    return { provider: () => new Map() }
  }, [])
  return (
    <SWRConfig value={swrConfig}>
      <GraphQLProviderWithSWR {...props} />
    </SWRConfig>
  )
}

export function useGraphQLContext() {
  const context = useContext(GraphQLContext)
  if (context === undefined) {
    throw new Error('useGraphQLContext must be used within a GraphQLProvider')
  }
  return context
}
