import clsx from 'clsx'
import React, { ForwardedRef } from 'react'
import { StateIcon } from '../Icon'
import { BoxElementAffix } from './BoxElementAffix'
import { BoxProps } from './types'

/**
 * Renders the outer UI of a box (border, outline, background)
 * also renders the affix/suffix if passed
 * when any immediate children is focused adds an outline to the box
 *
 * TODO: Make this a standalone component
 */
function BoxElementContainerInner(
  {
    as,
    error,
    disabled,
    state,
    size,
    prefix,
    suffix,
    children,
    outline,
  }: BoxProps & {
    children: React.ReactNode
    disabled: boolean
    as: 'Listbox' | 'Combobox' | 'div'
  },
  forwardedRef?: ForwardedRef<HTMLDivElement>,
) {
  const loadingOrDisabled = state === 'loading' || disabled
  return (
    <div
      className={clsx('w-full relative flex flex-row items-center border rounded', {
        'cursor-pointer': !loadingOrDisabled && as === 'Listbox',
        'cursor-not-allowed': loadingOrDisabled,
        'bg-gray-100': loadingOrDisabled,
        'bg-white': !loadingOrDisabled,
        'border-gray-300 hover:border-gray-800': !error && !loadingOrDisabled,
      })}
      ref={forwardedRef}
    >
      <BoxElementAffix affix={prefix} className="flex-0 text-gray-700 px-4" size={size} />
      {children}
      {state != null || (suffix != null && suffix !== 'boolean') ? (
        <div className="flex items-center px-4 gap-2">
          {state != null && <StateIcon size={size} state={state} />}
          <BoxElementAffix affix={suffix} className="flex-0 text-gray-700" size={size} />
        </div>
      ) : null}
      <div
        className={clsx(
          // renders over the rest of the container, renders the outline
          'absolute top-0 bottom-0 right-0 left-0 rounded pointer-events-none',
          'outline',
          {
            // we use peer-focus tailwindcss class to manage the outline
            // if any props.children is focused we add the outline
            // alternatively the user of this component can pass props.outline
            // to control that state manually
            'peer-focus:outline-NvTeal-300 peer-focus:outline-2': outline == null,
            'outline-0': !(error || outline === true),
            // pseudo-selectors take precedence over normal classes so if the element
            // is focused these other rules do not apply:
            'outline-NvTeal-300': outline === true,
            'outline-red-100': outline !== true,
            'outline-2': error || outline === true,
          },
        )}
      />
    </div>
  )
}

export const BoxElementContainer = React.forwardRef(BoxElementContainerInner)
