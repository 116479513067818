import { BatterySystemGeneration, TenantsAndProductsQuery, gql, useGQL } from '@northvolt/gql'
import { useMemo } from 'react'

const tenantsAndProductsQuery = gql`
  query tenantsAndProducts {
    products {
      name
    }
    tenants {
      name
    }
    # tags
  }
`

/**
 * returns all the products, tenants, tags and battery-system generations available
 * only tags used by this specific tenant are returned
 */
export function useEntityRelationships(type: 'pack' | 'core' | 'voltpack-mobile-hub') {
  const {
    data: { products, tenants },
  } = useGQL<TenantsAndProductsQuery>(tenantsAndProductsQuery)
  return useMemo(() => {
    return {
      // TODO add autocomplete for tags back when we fix backend bug
      // see https://linear.app/northvolt/issue/CB-1042/gql-query-to-fetch-tags-errors-out-when-tenant-=-northvolt
      tags: [] as string[],
      products: products.map((product) => {
        return {
          value: product.name ?? '',
          label: product.name ?? '',
        }
      }),
      tenants: tenants
        .map((tenant) => {
          return {
            value: tenant.name ?? '',
            label: tenant.name ?? '',
          }
        })
        .sort((a, b) => {
          // forces 'northvolt' to be the first one in the list
          if (a.value === 'northvolt') {
            return -1
          } else if (b.value === 'northvolt') {
            return 1
          }
          return 0
        }),
      generations: Object.keys(BatterySystemGeneration)
        .filter((gen) => {
          // B2 is only available for VPHs
          if (type === 'voltpack-mobile-hub' && gen !== 'B2') {
            return false
          }
          if (type !== 'voltpack-mobile-hub' && gen === 'B2') {
            return false
          }
          return true
        })
        .map((gen) => {
          return {
            // @ts-ignore
            value: BatterySystemGeneration[gen] as BatterySystemGeneration,
            label: gen,
          }
        })
        .sort((a, b) => {
          // forces 'D' to be the first one in the list
          if (a.value === 'D') {
            return -1
          } else if (b.value === 'D') {
            return 1
          }
          return 0
        }),
    }
  }, [type, products, tenants])
}
