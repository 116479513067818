import { Combobox, Listbox } from '@headlessui/react'
import clsx from 'clsx'
import React, { ForwardedRef } from 'react'
import { SizeValue } from '../useSizeScreen'
import { Option } from './Option'
import { OptionsItem } from './types'

export type OptionsProps = {
  as: 'Listbox' | 'Combobox'
  /**
   * React.ReactNode items (not strings) are rendered as is and can not be selected
   * or filtered
   * OptionsItems are rendered based on renderOptionsItem
   */
  list: (OptionsItem | React.ReactNode)[]
  /**
   * filters list based on this value
   */
  searchString: string | null
  size: SizeValue
  /**
   * used to know which value is selected
   */
  value?: OptionsItem | null
  /**
   * if there are no values to show, this message is shown instead
   * pass null to remove the message
   */
  noResultsMessage?: React.ReactNode
  /**
   * if not provided will render OptionsItems as a <Option as={optionsProps.as} ...>
   */
  renderOptionsItem?: ({
    label,
    value,
    selected,
  }: {
    label: string
    value: string
    selected: boolean
  }) => React.ReactNode
}

function OptionsInner(
  { as, list, size, searchString, value, noResultsMessage, renderOptionsItem }: OptionsProps,
  forwardedRef?: ForwardedRef<HTMLUListElement>,
) {
  let filteredList = list
  if (searchString != null) {
    const s = searchString.toLowerCase()
    filteredList = list.filter((item) => {
      if (typeof item === 'string') {
        return item.toLowerCase().includes(s)
      } else if (item != null && typeof item === 'object' && 'value' in item && 'label' in item) {
        return item.label.toLowerCase().includes(s)
      } else {
        // React.ReactNode
        return true
      }
    })
  }

  // for searchable lists we want to limit the options to display for performance reasons
  if (as === 'Combobox') {
    filteredList = filteredList.slice(0, 100)
  }

  const Options = as === 'Combobox' ? Combobox.Options : Listbox.Options

  let noResults: React.ReactNode = null

  if (filteredList.length === 0) {
    if (typeof noResultsMessage === 'string' || typeof noResultsMessage === 'undefined') {
      noResults = (
        <Option as={as} className="text-gray-700" item={null} selected={false} size={size}>
          {noResultsMessage ?? 'No results found'}
        </Option>
      )
    } else {
      noResults = noResultsMessage
    }
    if (noResults == null) {
      // don't even render <Options> if we got nothing to show
      // need to pass down the forwardedRef to prevent an error here
      return <Options className={clsx('hidden')} ref={forwardedRef} static />
    }
  }

  return (
    <Options
      className={clsx(
        'relative flex flex-col max-h-96 overflow-auto w-full',
        'border border-gray-300 rounded shadow-xl bg-white outline-none',
      )}
      ref={forwardedRef}
      static
    >
      {noResults}

      {filteredList.map((item) => {
        let itemLabel: string, itemValue: string
        if (typeof item === 'string') {
          itemLabel = item
          itemValue = item
        } else if (item != null && typeof item === 'object' && 'value' in item && 'label' in item) {
          itemLabel = item.label
          itemValue = item.value
        } else {
          // React.ReactNode
          return item
        }
        let selected = false
        if (typeof value === 'string') {
          selected = value === itemValue
        } else {
          selected = value?.value === itemValue
        }
        if (renderOptionsItem != null) {
          return renderOptionsItem({ label: itemLabel, value: itemValue, selected })
        }
        return (
          <Option as={as} item={itemValue} key={itemValue} selected={selected} size={size}>
            {itemLabel}
          </Option>
        )
      })}
      {as === 'Combobox' && filteredList.length === 100 && (
        <Option
          as={as}
          disabled={true}
          item={{ label: '', value: '' }}
          selected={false}
          size={size}
        >
          Search to see more items...
        </Option>
      )}
    </Options>
  )
}

export const Options = React.forwardRef(OptionsInner)
