import { faCheck, faXmarkLarge } from '@fortawesome/pro-regular-svg-icons'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'
import React from 'react'
import { InputState } from './types'
import { Size, useSizeScreen } from './useSizeScreen'

export type StandardIconProps = {
  icon: FontAwesomeIconProps['icon']
  size?: Size
  onClick?: (ev: React.MouseEvent<HTMLDivElement>) => void
  iconSpinning?: boolean
  marginLeft?: boolean
  marginRight?: boolean
  className?: string
  /**
   * if true does not set height/width on the icon
   * pass the size down inside className
   */
  customSize?: boolean
  testId?: string
}

/**
 * the default way of rendering icons for form components
 * it takes a "size" prop like the other components from this package
 * this component does not set icon-color, it can be set through
 * text CSS classes inside className prop
 */
export function StandardIcon({
  icon,
  size,
  onClick,
  iconSpinning,
  marginLeft,
  marginRight,
  className,
  customSize,
  testId,
}: StandardIconProps) {
  const screenSize = useSizeScreen(size)
  const small = screenSize === 'small'
  const medium = screenSize === 'medium'
  const large = screenSize === 'large'
  return (
    <div
      className={clsx('fill-current flex flex-shrink-0 items-center', className, {
        'pointer-events-none': onClick == null,
        'cursor-pointer': onClick != null,
        'h-3.5 w-3.5': !customSize && small,
        'h-4 w-4': !customSize && medium,
        'h-5 w-5': !customSize && large,
        'mr-2': marginRight && small,
        'mr-2.5': marginRight && medium,
        'mr-3': marginRight && large,
        'ml-2 order-last': marginLeft && small,
        'ml-2.5 order-last': marginLeft && medium,
        'ml-3 order-last': marginLeft && large,

        'animate-spin': iconSpinning,
      })}
      onClick={onClick}
      {...getTestId(testId)}
    >
      <FontAwesomeIcon className="w-full h-full" icon={icon} />
    </div>
  )
}

/**
 * renders an StandardIcon based on the InputState type
 */
export function StateIcon(
  props: { state: InputState | null } & Omit<StandardIconProps, 'icon' | 'iconSpinning'>,
) {
  const { state, className } = props
  let icon: FontAwesomeIconProps['icon']
  switch (state) {
    case 'success':
      icon = faCheck
      break
    case 'error':
      icon = faXmarkLarge
      break
    case 'loading':
      icon = faSpinner
      break
    case null:
    default:
      return null
  }

  return (
    <StandardIcon
      {...props}
      className={clsx(className, {
        'text-gray-700': state === 'loading',
        'text-red-100': state === 'error',
        'text-green-100': state === 'success',
      })}
      icon={icon}
      iconSpinning={state === 'loading'}
    />
  )
}
