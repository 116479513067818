import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@northvolt/tooltip'
import { useCallback, useState } from 'react'

const copyToClipboard = (value: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      try {
        document.execCommand(value)
        resolve(true)
      } catch (e) {
        reject()
      }
    } else {
      navigator.clipboard.writeText(value).then(
        () => {
          resolve(true)
        },
        () => {
          reject()
        },
      )
    }
  })

export default function CopyToClipboardText(props: { text: string; children?: any }) {
  const [copyVisible, setCopyVisible] = useState<boolean>(false)
  const [copyTooltipText, setCopyTooltipText] = useState<string>('Copy')

  const copyStringToClipboard = (value: string): void => {
    copyToClipboard(value)
      .then((success: boolean) => {
        if (success) {
          setCopyTooltipText('Copied!')
        } else {
          setCopyTooltipText('Failed')
        }
      })
      .catch(() => {
        setCopyTooltipText('Failed')
      })
      .finally(() => {
        setTimeout(() => {
          setCopyTooltipText('Copy')
        }, 1000)
      })
  }

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      copyStringToClipboard(props.text)
    },
    [props.text],
  )

  return (
    <div
      onMouseEnter={(): void => setCopyVisible(true)}
      onMouseLeave={(): void => setCopyVisible(false)}
    >
      <div onClick={onClick}>
        {props.children ?? props.text}
        {copyVisible && (
          <span className="opacity-70 text-sm ml-3 cursor-pointer hover:opacity-100">
            <Tooltip placement="right" title={copyTooltipText}>
              <FontAwesomeIcon icon={faCopy} />
            </Tooltip>
          </span>
        )}
      </div>
    </div>
  )
}
