import { ToastOptions, toast } from 'react-toastify'
import { NVError } from './NVError'
import { computeMessageId, maskErrorMessage } from './errorUtils'
import { captureException } from './sentry'

export function toastError(
  title: string,
  error: NVError | NVError[] | Error | Error[] | unknown | null,
  /**
   * if null will use error.message if error is NVError or NVError[]
   * otherwise will show generic error
   */
  message?: React.ReactNode,
  options?: ToastOptions & {
    /** if false will not capture the error, defaults to true */
    captureException?: boolean
  },
) {
  let finalMessage: React.ReactNode = message
  if (finalMessage == null) {
    finalMessage = []
    if (Array.isArray(error)) {
      finalMessage = error.map((err: unknown) => {
        return <div key={computeMessageId(err, title)}>{maskErrorMessage(err)}</div>
      })
      if (options?.captureException !== false) {
        error.forEach((err) => {
          // handled in ErrorHandlingContextProvider:
          console.error(err)
          captureException(err)
        })
      }
    } else {
      // eslint-disable-next-line
      finalMessage = [<div>{maskErrorMessage(error)}</div>]
      // handled in ErrorHandlingContextProvider:
      if (options?.captureException !== false) {
        console.error(error)
        captureException(error)
      }
    }
  }
  toast.error(<ToastContent message={finalMessage} title={title} />, {
    toastId: computeMessageId(error, title),
    ...options,
  })
}

export function toastSuccess(
  title: string,
  message: React.ReactNode | string[],
  options?: ToastOptions,
) {
  toast.success(<ToastContent message={message} title={title} />, {
    toastId: computeMessageId(message, title),
    ...options,
  })
}

export function toastWarning(
  title: string,
  message: React.ReactNode | string[],
  options?: ToastOptions,
) {
  toast.warn(<ToastContent message={message} title={title} />, {
    toastId: computeMessageId(message, title),
    ...options,
  })
}

export function toastInfo(
  title: string,
  message: React.ReactNode | string[],
  options?: ToastOptions,
) {
  toast.info(<ToastContent message={message} title={title} />, {
    toastId: computeMessageId(message, title),
    ...options,
  })
}

function ToastContent({
  message,
  title,
}: {
  message: React.ReactNode | React.ReactNode[]
  title: string
}) {
  return (
    <>
      <div className="flex space-x-1 mb-2 align-middle">
        <div className="font-bold text-lg mt-1">{title}</div>
      </div>
      {Array.isArray(message) ? message.map((m, idx) => <div key={idx}>{m}</div>) : message}
    </>
  )
}
