import { BatterySystemKind } from '@northvolt/gql'
import clsx from 'clsx'
import cores1 from '../images/cores1.png'
import cores2 from '../images/cores2.png'

export function getSystemImage(type: 'card' | 'table', kind: BatterySystemKind) {
  return (
    <img
      className={clsx(
        'w-auto',
        type === 'card' ? 'max-h-28' : 'h-16 mr-2',
        kind === BatterySystemKind.Subpack ? (type === 'card' ? 'px-1' : 'px-0.5') : null,
      )}
      src={kind === BatterySystemKind.Subpack ? cores1 : cores2}
    />
  )
}
