/**
 * Some features are dependent on specific minimum versions of the
 * spider or BMS software. This function enables providing a minimum
 * version and a current version, and return a boolean indicating
 * whether the feature is supported
 */

import { LowestAcoVersionFragment, gql } from '@northvolt/gql'

function isValidVersionArray(versionParts: string[]) {
  if (!Array.isArray(versionParts) || versionParts.length !== 3) {
    return false
  }
  return true
}

function isValidVersionPart(versionPart: string) {
  if (isNaN(parseInt(versionPart))) {
    return false
  }
  return true
}

/**
 * Version numbers used for BMS software are _almost_ following the standard for semantic
 * versioning but not quite, hence why we have a custom built version compare function to
 * accomodate for the convention used.
 * Example versions: 01.20.02
 * In other words, we add zero padding to version numbers between 1-9.
 * Therefore, this function must strip zero padding by converting each part of the version
 * before making a comparison (parseInt)
 *
 * It should be compatible with spider version since they are using proper semantic
 * versions
 *
 * The function returns
 * 1: If the _left_ version is greater than the _right_ version
 * 0: If the _left_ version is the same as the _right_ version
 * -1: If the _right_ version is greater than the _left_ version
 *
 * It throws if invalid left/right versions are used.
 *
 * @param {string} left left version
 * @param {string } right right version
 * @returns {number} 1,0,-1
 */
export function compareSoftwareVersions(left: string, right: string) {
  // remove semantic versioning prefix if found
  if (left.startsWith('v')) {
    left = left.slice(1)
  }
  if (right.startsWith('v')) {
    right = right.slice(1)
  }

  const leftVersionParts = left.split('.')
  const rightVersionParts = right.split('.')

  // validate correct length
  if (!isValidVersionArray(leftVersionParts) || !isValidVersionArray(rightVersionParts)) {
    // impossible to compare, return 1, we used to:
    // throw new Error(
    //   `Invalid version parts when comparing left/right versions. Left: ${leftVersionParts}, Right: ${rightVersionParts}`,
    // )
    // but.. it becomes annoying since we have so many custom builds out in the field with invalid
    // versions, so we just return 1 instead
    return 1
  }

  // validate individual parts
  // We could also:
  // if (![...leftVersionParts, ...rightVersionParts].every(isValidVersionPart)) {
  //   throw new Error(`Invalid left version part encountered`)
  // }
  // But then we don't get to log exactly which part that causes the exception
  for (const part of [...leftVersionParts, ...rightVersionParts]) {
    if (!isValidVersionPart(part)) {
      return 1
      // throw new Error(`Invalid left version part encountered: ${part}`)
    }
  }

  for (let i = 0; i < leftVersionParts.length; i++) {
    const left = Number(leftVersionParts[i])
    const right = Number(rightVersionParts[i])

    if (left === right) {
      continue
    }

    return left > right ? 1 : -1
  }

  return 0
}

/**
 * Fetch lowest possible ACO version from a pack or a core
 */
export function getLowestACOVersion(system: LowestAcoVersionFragment): null | string {
  // it's a core, no need for comparison...
  if (!system.subSystems) {
    if (!system?.state?.softwareVersions || system.state.softwareVersions.length === 0) {
      return null
    }
    for (const sw of system.state.softwareVersions) {
      if (sw.software === 'ACO') {
        return sw.version
      }
    }
  } else {
    let lowestACOVersion = '99.99.99'
    for (const core of system.subSystems) {
      if (!core?.state?.softwareVersions || core.state.softwareVersions.length === 0) {
        continue
      }
      for (const sw of core.state.softwareVersions) {
        if (sw.software === 'ACO') {
          try {
            const swComparison = compareSoftwareVersions(lowestACOVersion, sw.version)
            if (swComparison === 1) {
              lowestACOVersion = sw.version
            }
          } catch (e) {
            global.console.log(e)
            continue
          }
        }
      }
    }

    if (lowestACOVersion !== '99.99.99') {
      return lowestACOVersion
    }
  }

  return null
}

export const LowestACOVersion_fragment = gql`
  fragment LowestACOVersion on BatterySystem {
    subSystems {
      state {
        position
        softwareVersions {
          software
          version
        }
      }
    }
    state {
      softwareVersions {
        software
        version
      }
    }
  }
`
