import { config } from '@northvolt/config'
import { setErrorReportingUser } from '@northvolt/error-handling'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthUser, RolesEnum } from '../types'
import { Auth } from './Auth'

type AuthenticationContextValue = {
  /** the user currently logged in */
  user: AuthUser

  /** returns true if the user has at least one of the provided roles */
  hasRole: (roleName: RolesEnum | RolesEnum[]) => boolean

  /** returns the current token, if the token is expired will request a new one before returning */
  getToken: () => Promise<string>

  /** if true the token has been overriden */
  tokenOverrideStatus: boolean
  /** logs the user out of the system and redirects him back to the login page */
  logout: () => void
  /** overrides the current present token */
  overrideToken: (token: string | { authorization: string }) => void
  overrideTokenFromClipboard: () => void
  /** clears the token override */
  clearOverrideToken: () => void
  /** copies the token from clipboard */
  copyTokenToClipboard: () => void
}

const AuthenticationContext = React.createContext<AuthenticationContextValue | undefined>(undefined)

const offlineUser: AuthUser = {
  email: 'local@local.com',
  firstName: 'Local',
  fullName: 'Local Access',
  lastName: 'Access',
  roles: [RolesEnum.BATTERY_DIAGNOSTICS_READER],
  tenant: '',
  jobTitle: '',
}

const placeholderUser: AuthUser = {
  email: '',
  firstName: '',
  fullName: '',
  lastName: '',
  roles: [RolesEnum.BATTERY_DIAGNOSTICS_READER, RolesEnum.BATTERY_DIAGNOSTICS_WRITER],
  tenant: '',
  jobTitle: '',
}

const mockUser: AuthUser = {
  email: 'mock@email.com',
  firstName: 'Mock',
  fullName: 'Mock User',
  lastName: 'User',
  roles: [RolesEnum.BATTERY_DIAGNOSTICS_READER, RolesEnum.BATTERY_DIAGNOSTICS_WRITER],
  tenant: 'northvolt',
  jobTitle: '',
}

const TOKEN_OVERRIDE_LOCAL_STORAGE_KEY = 'AuthenticationProvider_token_override'
const tokenOverride = window.localStorage.getItem(TOKEN_OVERRIDE_LOCAL_STORAGE_KEY)

/** fallback to use for code that is not in the react tree and can't call useAuthenticationContext() */
export function getTokenSync(): string {
  if (tokenOverride != null) {
    return tokenOverride
  }
  return Auth.getTokenSync()?.tokenString ?? ''
}

export function AuthenticationProvider({
  loading,
  mock,
  children,
}: {
  /**
   * if the user's token is expired but he has a renew-token
   * we show this loading fallback until we can renew his token
   */
  loading: React.ReactNode
  /**
   * if true will mock the authentication, providing a mocked user and token
   * to be used in storybook
   */
  mock?: boolean
  children: React.ReactNode
}) {
  const appEnv = config.REACT_APP_ENVIRONMENT
  const [tokenString, setTokenString] = useState<string | null>(null)

  const { auth, getToken } = useMemo(() => {
    const auth = new Auth()

    return {
      auth,
      getToken: async () => {
        if (appEnv === 'offline') {
          return ''
        } else if (mock === true) {
          return ''
        } else if (tokenOverride != null) {
          return tokenOverride
        }
        const token = await auth.getToken()
        return token?.tokenString ?? ''
      },
    }

    // only runs again if any config value changed, which shouldn't happen normally
    // eslint-disable-next-line
  }, [JSON.stringify(config), mock])

  // getTokenSync() does not refresh the token (refreshing the token is an async operation)
  // therefor we need to periodically call await auth.getToken
  // which DOES refresh the token if it is expired
  useEffect(() => {
    if (appEnv === 'offline' || tokenOverride != null || mock === true) {
      // do not try to refresh token in these cases
      return
    }

    const validate = async () => {
      const t = await auth.getToken()
      setTokenString(t?.tokenString ?? null)
    }
    // every two minutes, auth.getToken() will refresh the token if the token is about
    // to expire in the next 5 minutes
    const id = setInterval(validate, 120 * 1000)
    validate()

    return () => {
      clearInterval(id)
    }
  }, [auth, mock, appEnv])

  const providerValue = useMemo<
    Omit<AuthenticationContextValue, 'user'> & { user: AuthUser | null }
  >(() => {
    let user: AuthUser | null = null
    if (mock === true) {
      user = mockUser
    } else if (appEnv === 'offline') {
      user = offlineUser
    } else {
      const token = getTokenSync()
      user = Auth.getUserData(token)
    }

    const hasRole: AuthenticationContextValue['hasRole'] = (roles: RolesEnum | RolesEnum[]) => {
      if (Array.isArray(roles)) {
        for (const role of roles) {
          if (user?.roles.includes(role)) {
            return true
          }
        }
        return false
      }
      return user?.roles.includes(roles) ?? false
    }

    if (mock === true) {
      return {
        user,
        hasRole,
        getToken,
        tokenOverrideStatus: false,
        logout: () => {
          // eslint-disable-next-line
          console.log('AuthenticationProvider logout')
        },
        overrideToken: () => {
          // eslint-disable-next-line
          console.log('AuthenticationProvider overrideToken')
        },
        overrideTokenFromClipboard: () => {
          // eslint-disable-next-line
          console.log('AuthenticationProvider overrideTokenFromClipboard')
        },
        clearOverrideToken: () => {
          // eslint-disable-next-line
          console.log('AuthenticationProvider clearOverrideToken')
        },
        copyTokenToClipboard: () => {
          // eslint-disable-next-line
          console.log('AuthenticationProvider copyTokenToClipboard')
        },
      } as Omit<AuthenticationContextValue, 'user'> & { user: AuthUser | null }
    }

    const overrideToken = (t: string) => {
      // t can be either a string with the token directly or
      // a JSON object with HTTP headers, in which case we want the "authorization" field
      let actualToken: string
      try {
        const httpHeaders = JSON.parse(t)
        actualToken = httpHeaders.authorization
      } catch (e) {
        actualToken = t
      }

      window.localStorage.setItem(
        TOKEN_OVERRIDE_LOCAL_STORAGE_KEY,
        actualToken.replace(/^Bearer\s*/, ''),
      )
      window.location.reload()
    }

    return {
      // in localhost use placeholderUser if we don't have a user from the token
      user: user ?? (appEnv === 'localhost' ? placeholderUser : null),
      hasRole,
      getToken,
      tokenOverrideStatus: tokenOverride != null,
      logout: () => {
        window.localStorage.removeItem(TOKEN_OVERRIDE_LOCAL_STORAGE_KEY)
        auth.logout()
      },
      overrideToken,
      overrideTokenFromClipboard: () => {
        getFromClipboard().then((clipboardToken) => {
          overrideToken(clipboardToken)
        })
      },
      clearOverrideToken: () => {
        window.localStorage.removeItem(TOKEN_OVERRIDE_LOCAL_STORAGE_KEY)
        window.location.reload()
      },
      copyTokenToClipboard: () => {
        const token = getTokenSync()
        copyToClipboard(
          // encode as an object with HTTP headers so it is easy to paste into graphql playground
          JSON.stringify(
            {
              apiUrl: config.REACT_APP_GRAPHQL_API,
              authorization: `Bearer ${token}`,
            },
            null,
            4,
          ),
        )
      },
    } as Omit<AuthenticationContextValue, 'user'> & { user: AuthUser | null }
    // rerun this when we get a new tokenString
    // eslint-disable-next-line
  }, [auth, getToken, appEnv, mock, tokenString])

  const user = providerValue.user
  useEffect(() => {
    if (user == null) {
      setErrorReportingUser(null)
    } else {
      setErrorReportingUser({
        email: user.email,
        roles: user.roles,
        tenant: user.tenant,
      })
    }
  }, [user])

  if (providerValue.user == null) {
    return <>{loading}</>
  }

  return (
    <AuthenticationContext.Provider value={providerValue as AuthenticationContextValue}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthenticationContext() {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error('useAuthenticationContext must be used within an AuthenticationProvider')
  }
  return context
}

const copyToClipboard = (value: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      try {
        document.execCommand(value)
        resolve(true)
      } catch (e) {
        reject()
      }
    } else {
      navigator.clipboard.writeText(value).then(
        () => {
          resolve(true)
        },
        () => {
          reject()
        },
      )
    }
  })
}

const getFromClipboard = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (navigator?.clipboard?.readText == null) {
      const value =
        // eslint-disable-next-line
        window.prompt(`Browser doesn't support pasting. Paste token here instead:`) ?? ''
      resolve(value)
    } else {
      navigator.clipboard
        .readText()
        .then((value) => {
          resolve(value)
        })
        .catch(reject)
    }
  })
}
