import { faCheck, faHyphen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTestId } from '@northvolt/test-utils'
import clsx from 'clsx'
import { ReactNode } from 'react'

export type CheckboxProps = {
  id: string
  state: boolean | 'checked' | 'unchecked' | 'indeterminate'
  disabled?: boolean
  onClick?: (id: string) => void
  children?: ReactNode
  className?: string
  testId?: string
  title?: string
  customColor?: string
  disableHoverStyle?: boolean
}

export function Checkbox({
  id,
  state,
  disabled,
  onClick,
  children,
  className,
  testId,
  title,
  customColor,
  disableHoverStyle,
}: CheckboxProps) {
  let icon: React.ComponentProps<typeof FontAwesomeIcon>['icon'] | null = null
  if (state === 'indeterminate') {
    icon = faHyphen
  } else if (state === 'checked' || state === true) {
    icon = faCheck
  }

  const stateChecked = state === true || state === 'checked' || state === 'indeterminate'
  const stateUnchecked = state === 'unchecked' || state === false

  // to customize color
  let color = {}
  if (customColor != null) {
    if (disabled !== true && stateChecked) {
      color = { backgroundColor: customColor }
    } else if (disabled !== true && stateUnchecked) {
      color = { borderColor: customColor }
    }
  }

  return (
    <div
      className={clsx(
        {
          'flex items-center': Boolean(children),
          'cursor-pointer': disabled !== true,
        },
        className,
      )}
      onClick={() => disabled !== true && onClick?.(id)}
      {...getTestId(testId)}
      title={title ?? ''}
    >
      <div
        className={clsx('relative p-2 w-fit group', {
          'cursor-pointer': disabled !== true,
        })}
      >
        <div
          className={clsx(
            'w-5 h-5 flex justify-center items-center rounded-sm',
            !customColor && {
              'bg-powerGreen': disabled !== true && stateChecked,
              'border-gray-700': disabled !== true && stateUnchecked,
            },
            {
              'bg-gray-400': disabled === true && stateChecked,
              'border-gray-400': disabled === true && stateUnchecked,
            },
            { 'border-solid border-2': stateUnchecked },
          )}
          style={color}
        >
          {icon != null && <FontAwesomeIcon className="text-white" icon={icon} />}
        </div>
        {disabled !== true && customColor == null && !disableHoverStyle && (
          <span className="absolute w-9 h-9 top-0 left-0 bg-powerGreen/20 opacity-0 group-hover:opacity-100 rounded-full transition-opacity" />
        )}
      </div>
      {children}
    </div>
  )
}
