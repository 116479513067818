export enum ConnectivityState {
  Online = 'Online',
  RecentlyOnline = 'RecentlyOnline',
  Offline = 'Offline',
  OfflineLongTime = 'OfflineLongTime',
  Unknown = 'Unknown',
  Local = 'Local',
}

export type ConnectivityInformation = {
  state: ConnectivityState
  lastSeen: string | null
}
