import { faUsb } from '@fortawesome/free-brands-svg-icons'
import { faCircle, faDotCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ConnectivityInformation, ConnectivityState } from './types'

export function ConnectivityInformationBadge({
  connectivity,
  showLastSeen,
}: {
  connectivity: ConnectivityInformation
  showLastSeen?: boolean
}) {
  return (
    <>
      <ConnectivityStateBadge state={connectivity.state} />
      {showLastSeen && (
        <div className="text-sm text-gray-700 tracking-wide">{connectivity.lastSeen}</div>
      )}
    </>
  )
}

function getConnectivityProps(state: ConnectivityState): {
  label: string
  iconProps: FontAwesomeIconProps
  iconClassName: string
} {
  switch (state) {
    case ConnectivityState.Local:
      return {
        label: 'Local',
        iconProps: { icon: faUsb, rotation: 270 },
        iconClassName: 'text-powerGreen',
      }
    case ConnectivityState.RecentlyOnline:
      return {
        label: 'Recently Online',
        iconProps: { icon: faDotCircle },
        iconClassName: 'text-powerGreen',
      }
    case ConnectivityState.Offline:
      return {
        label: 'Offline',
        iconProps: { icon: faDotCircle },
        iconClassName: 'text-gray-600',
      }
    case ConnectivityState.OfflineLongTime:
      return {
        label: 'Offline long time',
        iconProps: { icon: faCircle },
        iconClassName: 'text-gray-600',
      }
    case ConnectivityState.Unknown:
    default:
      return {
        label: 'Unknown',
        iconProps: { icon: faCircle },
        iconClassName: 'text-gray-700',
      }
  }
}

type ConnectivityStateBadgeProps = {
  state: ConnectivityState
  withLabel?: boolean
}

export function ConnectivityStateBadge({ state, withLabel = true }: ConnectivityStateBadgeProps) {
  if (state === ConnectivityState.Online) {
    return (
      <div className="flex items-center">
        <span className="flex h-3 w-3 delay-1000 relative">
          <span className="transition animate-ping absolute inline-flex h-full w-full rounded-full bg-powerGreen opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-powerGreen" />
        </span>
        {withLabel && <span className="text-gray-700 tracking-wide ml-2">Online</span>}
      </div>
    )
  } else {
    const { label, iconProps, iconClassName } = getConnectivityProps(state)
    return (
      <div className="flex items-center">
        <span className={iconClassName}>
          <FontAwesomeIcon className="inline text-sm" {...iconProps} />
        </span>
        {withLabel && <span className="text-gray-700 tracking-wide ml-2">{label}</span>}
      </div>
    )
  }
}
