import { Button } from '@northvolt/form'
import { computeMessageId, maskErrorMessage, showDebugInformation } from './errorUtils'

export type ErrorTypes = 'not-found' | 'generic'

interface ErrorProps {
  errorType: ErrorTypes
  title: string
  /**
   * can be an array of errors, error.message is only shown for errors that extend NVError
   */
  error?: unknown | unknown[] | null
  /**
   * if provided will show a "Retry" button that invokes this function when clicked
   */
  onRetryPressed?: () => void

  /**
   * if provided will show a "go back" button that nagivates the user back in the page's history
   */
  showBackButton?: boolean
}

export function ErrorContainer({
  errorType,
  error,
  title,
  onRetryPressed,
  showBackButton,
}: ErrorProps) {
  return (
    <div className="flex flex-wrap justify-center m-2">
      <h1 className="flex justify-center w-full text-xl text-black-light font-bold">{title}</h1>
      <div className="flex justify-center w-full my-8 flex-wrap text-black-light text-base">
        {errorType === 'not-found' && (
          <p>Snap! Seems like we can&apos;t find the page you requested.</p>
        )}
        {errorType === 'generic' && (
          <>
            {Array.isArray(error) ? (
              error.map((err) => {
                return <ErrorBox error={err} key={computeMessageId(err, title)} />
              })
            ) : (
              <ErrorBox error={error} />
            )}
          </>
        )}
      </div>

      <div className="flex justify-center gap-2">
        {showBackButton && (
          <Button
            className="flex justify-center w-full mx-4 sm:w-56 sm:mx-0"
            onClick={() => {
              // can't use useNavigate() from react-router here
              // as we use this component inside ErrorBoundaries which can be mounted above
              // <Router> in the tree (which makes useNavigate() not available)
              // calling history.back() has the same effect
              window.history.back()
            }}
            size="large"
            style={onRetryPressed != null ? 'secondary' : 'primary'}
          >
            Go back
          </Button>
        )}
        {onRetryPressed != null && (
          <Button
            className="flex justify-center w-full mx-4 sm:w-56 sm:mx-0"
            onClick={onRetryPressed}
            size="large"
            style="primary"
          >
            Retry
          </Button>
        )}
      </div>
    </div>
  )
}

function ErrorBox({ error }: { error: unknown | null }) {
  return (
    <div>
      {error != null && <div className="w-full text-center mt-2">{maskErrorMessage(error)}</div>}
      {showDebugInformation && error instanceof Error && (
        <pre className="max-w-full overflow-x-scroll p-2">
          <code>{error.stack}</code>
        </pre>
      )}
    </div>
  )
}
